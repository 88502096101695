html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button {
    cursor: pointer;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:root {
    font-size: 13px;
    --color-creme-000: #fafafa;
    --color-maeve-100: #100E18;
    --color-maeve-200: #1a1728;
    --color-maeve-300: #1f1c2f;
    --color-maeve-400: #242037;
    --color-maeve-500-hsl: 250.9 26.4% 24.5%;
    --color-maeve-500: hsl(var(--color-maeve-500-hsl));
    --color-maeve-650: #554e7b;
    --color-maeve-800: #848294;
    --color-maeve-850: #aaa9af;
    --color-maeve-900: #d6d5dc;
    --color-amber-500: #ffcb7e;
    --color-plink-500: #ff5e85;
    --color-cryan-500: #00c2e0;
}

html {
    height: 100%;
    color: var(--color-creme-000);
    background-color: var(--color-maeve-200);
}

@media screen and (min-height: 700px) {
     :root {
        /* font-size: 17px; */
    }
}

body {
    overflow: hidden;
    display: grid;
    height: 100%;
    min-width: 100dvw;
    padding: 1rem;
    /* min-height: 100vh; */
    /* height: 100vh; */
    font-family: 'Space Mono', monospace;
    line-height: 1.3;
    gap: 1rem;
}

body * {
    /* fixes weird text overflow thing?? */
    max-width: calc(100dvw - 2rem);
}

@media screen and (max-aspect-ratio: 13/11) {
    /* portrait */
    body {
        grid-template-areas: "content" "alert" "player" "tabs";
        /* grid-template-rows: auto 4rem max-content max-content; */
        grid-template-rows: auto 4rem 11rem 4rem;
        /* trying to fix horizomntal scrolling player */
        grid-template-columns: 1fr;
        padding: 1rem 1rem 0.5rem;
    }
    body>* {
        grid-column: 1;
    }
}

@media screen and (min-aspect-ratio: 13/11) {
    /* landscape */
    body {
        grid-template-areas: "player content" "player alert" "player tabs";
        grid-template-rows: 1fr max-content;
        grid-template-columns: minmax(200px, 20dvw) 1fr;
    }
    #player {
        grid-column: 1;
    }
    .c-tabs,
    .content,
    .loader,
    #alert {
        grid-column: 2;
    }
}

.content,
.loader {
    grid-row-start: 1;
    grid-row-end: 3;
}

.content {
    overflow-y: auto;
    min-height: 100%;
}

*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    /* width: 40px; */
}

*::-webkit-scrollbar-thumb {
    background-color: #848294;
    border-radius: 1rem;
    border: 0.3rem solid var(--color-maeve-200);
    transition: background-color 0.4s;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #FFCB7E;
    cursor: pointer;
}

.content > * {
    position: relative;
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.height-100pc {
    height: 100%;
}

audio {
    width: 100%;
    color: inherit;
}

#searchResults {
    /* width: 100%; */
}

li {
    list-style-type: none;
}

.results {
    /* overflow: auto; */
    /* overflow-x: hidden; */
    overflow-y: auto;
}

.results > * {
    max-width: 100%;
}

.results__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

#queueFuture {
    min-height: 40dvh;
}

.results__list:hover> :hover,
.results__list:focus-within> :focus {
    /* transform: scale(1.1); */
    opacity: 1;
}

@media (min-width: 1024px) {
    .results__list--grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(530px, 1fr));
        gap: 0.5rem;
    }
    .results__list--grid>li+li {
        margin-top: 0 !important;
    }
}

.results__list--albums {
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)) !important;
}

@media (min-width: 640px) {
    .results__list--albums {
        gap: 1.2rem;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    }
}

.results__list>li {}


/* .results__list > li:is(:hover, :focus, :active) {
  background-color: var(--color-maeve-400)
} */

.album {
    font-size: .8em;
    opacity: .7;
}

.thumbnail {
    --thumbnailSize: 3rem;
    flex-shrink: 0;
    width: var(--thumbnailSize);
    height: var(--thumbnailSize);
    background-color: rgba(0, 0, 0, .4);
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
}

.thumbnail--album {
    border-radius: 3px;
    box-shadow: inset 1px 1px 0 rgba(255, 255, 255, .1), 2px 2px 0 rgba(0, 0, 0, .1);
    /* transform: scale(1.45) translateX(-5px); */
}

body:not(.is-playing) .thumbnail {
    animation-play-state: paused;
}

.thumbnail img {
    width: inherit;
    height: inherit;
    object-fit: cover;
}

.thumbnail img[src=""] {
    opacity: 0;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: .25rem;
}

.tag,
.genre {
    display: inline-flex;
    border-radius: 12px;
    padding: 2px 8px;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    color: #3E2652;
}

.tag {
    background: #EECCFE;
}

.genre {
    background: #CCF8FE;
}

button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: 4rem; */
    height: 4rem;
    padding: .5rem 1rem;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    color: var(--color-creme-000);
}

.state-invisible {
    position: relative;
    z-index: -10;
    opacity: 0;
    pointer-events: none;
    user-select: none;
}

.cursor-default {
    cursor: default;
}

button>* {
    pointer-events: none;
    user-select: none;
}

#form {
    display: flex;
    width: 100%;
    gap: 1rem;
}

#form>* {
    height: 3rem;
    padding: 0 1rem;
}

#input {
    height: 3rem;
    padding: .5rem 1rem;
    background-color: var(--color-maeve-100);
    border: 2px solid var(--color-maeve-800);
    border-radius: 4px;
    color: var(--color-creme-000);
    outline: none;
}

#input:is(:hover, :focus, :active) {
    border-color: var(--color-maeve-900);
}

input,
button,
audio {
    font-family: inherit;
}

input[type="search"] {
    flex-grow: 1;
}

.c-tabs {
    overflow: hidden;
}

.c-tabs--nav {
    grid-area: tabs;
    box-shadow: 0 -1px 0 rgb(255 255 255 / 10%);
}

.c-tabs__button-bar {
    width: 100%;
    display: flex;
    border-radius: 6px;
    align-self: flex-start;
    /* background-color: var(--color-maeve-500); */
}

.c-tabs__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    width: 100%;
    height: 3.5rem;
    text-transform: uppercase;
    transition: .05s ease-in all;
    border-radius: inherit;
}

.c-tabs__button:hover {
    backdrop-filter: brightness(1.1);
}

.c-tabs__button.active {
    color: var(--color-amber-500);
}

.c-tabs__button-icon {
    flex-shrink: 0;
    fill: currentColor;
}

.button {}

.button--secondary {
    --color-background: var(--color-cryan-500);
    --color-foreground: var(--color-maeve-100);
    height: 2rem;
    background-color: var(--color-background);
    color: var(--color-foreground);
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 6px;
}

.button--warning {
    --color-background: var(--color-plink-500);
}

.section-title {
    letter-spacing: .03em;
    font-size: 1.4rem;
    /* padding-bottom: .5rem;
  margin-bottom: .5rem; */
}

.content,
.c-tabs {
    display: flex;
    gap: 1rem;
    /* grid-column: 1; */
}

.c-tabs {
    /* padding: 1rem; */
}

.player {
    z-index: 10;
    position: relative;
    grid-area: player;
    display: flex;
    flex-direction: column;
    background: var(--color-maeve-400);
    box-shadow: 0 0 14px rgb(0 0 0 / 20%);
    border-radius: 6px;
    overflow: hidden;
    transition: all .1s ease-in;
}

.is-playing .player {
    background: url('play-rainbow.gif') center center no-repeat;
    background-size: cover;
    background-blend-mode: lighten;
    background-color: var(--color-maeve-650);
}

.is-playing .player:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px) contrast(100%) brightness(80%) saturate(100%);
    z-index: -1;
}


/* .player, */

.c-tabs {
    transition: .15s ease-in;
    will-change: transform;
}

.c-player-controls {
    display: flex;
    gap: .5rem;
    margin-top: auto;
    /* background-color: var(--color-maeve-500); */
    justify-content: center;
    align-items: center;
    padding: .75rem;
}

.is-playing .c-player-controls {
    background-color: transparent;
}

.c-player-controls__button {
    --size: 3rem;
    transition: .1s linear color;
    overflow: hidden;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    flex-shrink: 0;
    transition: .1s linear background-color;
}

.c-player-controls__button:is(:hover) {
    background-color: var(--color-maeve-650);
}

.c-player-controls__button:is(:active) {
    color: orange;
}

.c-player-controls__icon {
    --size: 1.5rem;
    width: var(--size);
    height: var(--size);
    flex-shrink: 0;
}

.c-player-controls__icon:focus-wid {}

.is-focused-search-input {}

.search-form {
    display: flex;
    width: 100%;
    border-radius: 12px;
    position: sticky;
    top: 0rem;
    z-index: 1;
}

.search-form button {
    position: fixed;
    z-index: -10;
    opacity: 0;
    width: 1px;
    height: 1px;
}

.back-from-search {
    display: none;
}

.o-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    top: -1px;
    left: -1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip-path: inset(50%);
}

.player-meta {
    display: flex;
    align-items: center;
    /* gap: 1rem; */
    height: 100%;
}

.player-meta,
.c-player-controls {
    /* backdrop-filter: blur(5px); */
}

.track-meta,
.player-controls {
    /* padding: 1rem; */
}

.track-meta {
    width: 100%;
    display: flex;
    gap: .25rem;
    flex-wrap: wrap;
    padding-right: 1rem;
}

.track-meta__title {
    width: 100%;
    flex-grow: 1;
}

.track-meta__artists {
    font-size: .8em;
    opacity: .7;
}

.track-meta__album {
    font-size: .8em;
    opacity: .7;
}

.result-meta {
    display: flex;
    align-items: center;
    gap: .75rem;
}

@media screen and (min-aspect-ratio: 13/11) and (min-height: 600px) {
    .track-meta__title {
        padding-top: .5rem;
        font-size: 22px;
    }
    .track-meta__artists {
        font-size: 16px;
    }
    .track-meta__album {
        margin-top: 1rem;
        font-size: 12px;
    }
}

@media screen and (max-aspect-ratio: 13/11) and (max-height: 840px) {
    /* landscape and fairly short */
    body.is-focused-search-input {
        grid-template-areas: "content" "alert" "player" "tabs";
        grid-template-rows: auto 0px 0px 0px;
        /* grid-template-areas: 
    "tabs"
    "content"
    "player";
    grid-template-rows: 0px auto 0px; */
    }
    /* .is-focused-search-input .player {
    transform: translateY(100%);
  }

  .is-focused-search-input .c-tabs {
    transform: translateY(-100%);
  } */
}

@media screen and (max-aspect-ratio: 13/11) {
    .playPause {
        /* display: none; */
    }
}

@media screen and (min-height: 500px) {
    .playPause {
        /* display: none; */
    }
    .c-tabs__button {
        height: 4rem;
    }
}

@media screen and (min-aspect-ratio: 13/11) and (min-height: 500px) {
    /* landscape and tall */
    .content {
        /* padding: 1rem; */
    }
    #input {
        height: 3.5rem;
    }
}

#audio {
    z-index: -10;
    position: fixed;
    opacity: 0;
    pointer-events: none;
}

.playPause:is(:hover, :focus) {
    background-color: var(--color-maeve-650);
}

.playPause {
    background-color: var(--color-maeve-650);
}

.playPause:not(.paused) .c-player-controls__play-icon {
    display: none;
}

.playPause:not(.paused) .c-player-controls__pause-icon {
    display: block;
}

.playPause.paused .c-player-controls__play-icon {
    display: block;
}

.playPause.paused .c-player-controls__pause-icon {
    display: none;
}

@media screen and (min-aspect-ratio: 13/11) {
    /* landscape */
    .player-meta {
        flex-direction: column;
        padding: .5rem;
        transition: all .1s ease-in;
    }
    .album-artwork:not([style])+.track-meta {
        opacity: 0;
    }
    .track-meta {
        /* background-color: var(--color-maeve-400); */
        color: #F9FAFB;
        padding: 1rem;
        flex-direction: column;
    }
    .player-controls {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }
    .player.is-hidden .player-meta {
        /* transform: translateY(2rem); */
        opacity: 0;
    }
}

@media screen and (min-aspect-ratio: 13/11) and (max-width: 1280px) {
    .c-player-controls {
        display: grid;
        grid-template-columns: min-content min-content min-content;
        grid-template-rows: min-content min-content;
    }
    .previousTrack {
        grid-row: 2;
    }
    .skipTrack {
        grid-row: 2;
        grid-column: 3;
    }
}

@media screen and (max-aspect-ratio: 13/11) {
    /* portrait */
    .player {}
    .player-meta {
        gap: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .player.is-hidden {
        transform: translateY(2rem);
        opacity: 0;
    }
    /* .track-meta  {
    padding: 1rem;
  } */
    .player-controls {
        padding-left: 1rem;
        padding-right: 1rem;
        /* padding-bottom: 1rem; */
    }
    #currentAlbum:not(:empty):before {
        content: " – ";
    }
}

.album-artwork {
    background-color: rgba(0, 0, 0, .4);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
}

@media screen and (min-aspect-ratio: 13/11) and (max-height: 599px) {
    /* landscape and short */
    .player {
        overflow: hidden;
    }
    .player-meta {
        position: relative;
    }
    .track-meta {
        position: absolute;
        z-index: 1;
        bottom: 0;
        /* transform: translateY(-100%); */
        font-weight: 700;
    }
    .album-artwork {
        width: 100%;
        padding-bottom: 100%;
        border-radius: 0;
    }
    .is-focused-search-input {
        grid-template-areas: "player content" "player tabs";
        grid-template-rows: 1fr 0px;
        grid-template-columns: minmax(200px, 20vw) 1fr;
    }
    .is-focused-search-input #search-form {
        position: fixed;
        inset: 0;
        z-index: 12;
        display: flex;
        align-items: center;
        padding: 2rem;
        background-color: var(--color-maeve-200);
    }
    .is-focused-search-input #search-form>* {
        height: 4rem;
        min-width: 4rem;
    }
    .is-focused-search-input .back-from-search {
        display: block;
    }
    .is-focused-search-input .c-tabs {
        transform: translateY(100%);
    }
    button {
        padding: .5rem .75rem;
    }
    .player-controls {
        flex-wrap: wrap;
        gap: .5rem;
        justify-content: space-between;
    }
    audio {
        position: fixed;
        z-index: -10;
        opacity: 0;
        width: 1px;
        height: 1px;
        background: transparent;
    }
    .skipBackward,
    .playPause,
    .skipForward {
        flex-grow: 1;
    }
    .skipTrack {
        flex-grow: 1;
        width: 100%;
    }
    .player-meta {
        gap: 0rem;
    }
    /* .track-meta__title,
  .track-meta__title > *, */
    .track-meta__artist,
    .track-meta__artist>*,
    .track-meta__album,
    .track-meta__album>* {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

audio::-webkit-media-controls-enclosure {
    background-color: transparent;
    color: var(--color-maeve-900);
}

audio::-webkit-media-controls-current-time-display {
    color: var(--color-maeve-900) !important;
}

audio::-webkit-media-controls {
    font-family: inherit !important;
}

audio::-webkit-media-controls-timeline {
    display: none;
}

audio::-webkit-media-controls-play-button {
    margin-right: auto;
    /* margin-left: */
}

audio::-webkit-media-controls-volume-control-container {
    display: none;
}

audio::-webkit-media-controls-panel {
    font-family: inherit !important;
}

audio::-internal-media-controls-overflow-button {
    display: none !important;
    /* margin-left: auto; */
}

audio::-webkit-media-controls-time-remaining-display {
    margin-right: auto;
}

#queue {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

#queuePast>li:not(:hover, :focus-within) {
    opacity: .6;
}

#queueCurrent:empty:before {
    /* content: "Nothing played yet, obviously."; */
    padding-left: 1.5rem;
}

#queueCurrent>li {
    outline: 2px solid var(--color-amber-500);
    outline-offset: -2px;
    border-radius: 4px;
}

@keyframes playingThumbnail {
    from {
        opacity: .3;
        transform: rotate(0deg);
    }
    to {
        opacity: .3;
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#queueCurrent .thumbnail {}

#alert {
    z-index: 20;
    position: relative;
    top: 100%;
    left: 50%;
    grid-area: alert;
    width: max-content;
    max-width: 80%;
    height: min-content;
    margin: 0;
    padding: .5rem 1rem;
    background: var(--color-plink-500);
    color: white;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .8);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
    border-radius: 8px;
    transition: opacity .25s ease-out;
    transform: translateX(-50%) translateY(-100%);
}

#alert:not([open]) {
    user-select: none;
    pointer-events: none;
    opacity: 0;
}

#alert p {
    font-size: .9rem;
}

@media screen and (min-aspect-ratio: 13/11) and (min-height: 600px) {
    /* landscape and tall */
    .album-artwork {
        width: 100%;
        padding-bottom: 100%;
        border-radius: 0;
    }
    .player {
        flex-direction: column;
    }
    .player-controls {
        /* gap: 1rem; */
        /* flex-direction: column; */
        flex-wrap: wrap;
    }
    .player-controls>* {
        flex-grow: 1;
    }
    #audio {
        width: 100%;
        /* flex-grow: 1; */
    }
    dialog {
        top: calc(100% - 4rem);
    }
}

@media screen and (max-aspect-ratio: 13/11) and (min-width: 700px) {

    /* portrait but 700px+ */

    body {
        grid-template:
        "content"
        "alert" 4rem
        "player" 7rem
        "tabs" 4rem / 1fr;
        padding: 1rem 1rem .5rem;
    }

    .player {
        padding-left: 1rem;
        padding-right: 2rem;
        flex-direction: row;
    }

    .player-meta {
        flex-grow: 1;
    }

    .c-player-controls {
        margin-top: unset;
        gap: 2vw;
    }

}

@media screen and (max-aspect-ratio: 13/11) {
    /* portrait */

    .track-meta {
        padding-left: 0;
    }
    .album-artwork {
        margin-left: 1rem;
        width: 4rem;
        height: 4rem;
    }
}

.results:not(.is-loading)+.loader {
    opacity: 0;
    /* transform: scale(.98); */
    transition: .6s ease-out opacity;
    transition-delay: .1s;
}

.loader {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* inset: 0; */
    user-select: none;
    pointer-events: none;
    transition: .2s ease-out opacity;
}

.loader__image {
    --size: 96px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    width: var(--size);
    height: var(--size);
}

.flex {
    display: flex;
}

.direction-column {
    flex-direction: column;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.mb-10 {
    margin-bottom: 1rem;
}

.py-10 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.px-15 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.c-track-result {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: .5rem;
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    line-height: 1.25;
    border-radius: 4px;
    background-color: var(--color-maeve-300);
}

.c-track-result--album {
    display: grid;
    grid-template-areas:
    "artwork"
    "metadata"
    "controls"
    ;
    height: max-content !important;
    grid-gap: 1rem;
}

.c-track-result--album .thumbnail {
    grid-area: artwork;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
}

.c-track-result--album .c-track-result__button--play,
.c-track-result--album .c-track-result__controls {
    grid-area: controls;
}

.c-track-result--album .c-track-result__button--play {
    transform: none;
    background-color: transparent;
    box-shadow: none;
}

.c-track-result--album .c-track-result__controls {
    margin-left: 3rem;
}

.c-track-result--album .c-track-result__meta {
    grid-area: metadata;
    padding-right: .25rem;
    padding-left: .25rem;
    gap: .5rem;
}

.c-track-result--album .c-track-result__meta-links {
    flex-direction: column;
    gap: .15rem;
}

.c-track-result:is(:hover, :focus, :active) {
    background-color: var(--color-maeve-400);
}

.c-track-result__meta {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-content: center;
    row-gap: .0rem;
    column-gap: .5rem;
    overflow: hidden;
    max-width: 100%;
}

.c-track-result__meta-links {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-content: center;
    column-gap: .5rem;
    white-space: nowrap;
    flex: 1;
    min-width: 0;
}

.c-track-result__meta-links>* {}

.c-track-result__track-name,
.c-track-result__meta-links>*:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.c-track-result__track-name {
    width: 100%;
    font-size: 14px;
}

.u-color-cryan-500 {
    color: var(--color-cryan-500);
}

.u-color-plink-500 {
    color: var(--color-plink-500);
}

.u-color-maeve-850 {
    color: var(--color-maeve-850);
}

.u-gap-05rem {
    gap: .5rem;
}

.u-gap-1rem {
    gap: 1rem;
}

.c-track-result__featured-artists {
    color: var(--color-maeve-850);
}

.c-track-result__featured-artists::before {
    content: "feat. ";
}

.c-track-result__album {}

.c-track-result__controls {
    display: flex;
    gap: .5rem;
}

.c-track-result__button {
    --size: 2rem;
    flex-shrink: 0;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    transition: all .1s ease-in;
    border-radius: 50%;
}

.c-track-result__button--play {
    background-color: hsl(var(--color-maeve-500-hsl) / 1);
    box-shadow: -2px 1px 4px 1px rgba(0, 0, 0, .4), inset 0 1px 0 rgba(255, 255, 255, .15);
}

.c-track-result__button--play .c-track-result__button-icon {
    transform: translateX(6%);
}

.c-track-result:is(:hover, :focus) .c-track-result__button--addToQueue:not(:hover, :focus) {
    background-color: hsl(var(--color-maeve-500-hsl) / 0.6);
}

.c-track-result__button:is(:hover, :focus) {
    background-color: var(--color-maeve-650);
}

.c-track-result__button-icon {
    --size: 1.5rem;
    flex-shrink: 0;
    width: var(--size);
    height: var(--size);
}

.c-track-result__inline-icon {
    --size: 12px;
    display: inline-flex;
    vertical-align: middle;
    margin-right: .15rem;
    flex-shrink: 0;
    width: var(--size);
    height: var(--size);
}

@media (max-width: 359px) {
    .c-track-result__artists {
        /* width: 100%; */
    }
    .c-track-result:not(.c-track-result--album) .c-track-result__thumbnail {
        display: none;
    }
    .c-track-result__button--play {
        margin-right: .5rem;
    }
}

@media (min-width: 360px) {
    .c-track-result__button--play {
        transform: translateX(-1rem);
    }
}

@media (min-height: 540px) {
    .c-track-result {
        height: 4.5rem;
    }
}

.c-select-invisible {
    padding: .25em;
    padding-right: .75em;
    border-radius: 3px;
    background: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, .1);
}